import React, {Suspense} from 'react';
import {
    BrowserRouter as Router,
    Route, Routes
} from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import Details from "./pages/Details";
import About from "./pages/About";
import AdvancedSearch from "./pages/AdvancedSearch";
import ContactUs from "./pages/ContactUs";
import LoadingScreen from "./components/LoadingScreen";
import Services from "./pages/Services";
import {BootstrapProvider} from "./components/context/BootstrapContext";
import SearchResults from "./pages/SearchResults";
import "./css/multiselect.css";
import {LoadingProvider} from "./components/context/LoadingContext";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./components/Footer";
import Reviews from "./pages/Reviews";
import {Helmet} from "react-helmet";
import i18n from "i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";
import RentableProperties from "./pages/RentableProperties";
import Impressum from "./pages/Impressum";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: "hu",
        debug: true,
        ns: ['translation', "property_fields", "about_us", "seo", "impressum"],
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

function App() {
    return (
        <><Helmet>
            <meta charSet="utf-8"/>
            <title>MagicHouse.hu ingatlaniroda</title>
        </Helmet>
            <LoadingProvider>
                <BootstrapProvider>
                    <Suspense fallback={<LoadingScreen/>}>
                        <Router>
                            <Routes>
                                <Route path="/about" element={<About/>}/>
                                <Route path="/privacy" element={<PrivacyPolicy/>}/>
                                <Route path="/impressum" element={<Impressum/>}/>
                                <Route path="/services" element={<Services/>}/>
                                <Route path="/contact" element={<ContactUs/>}/>
                                <Route path="/reviews" element={<Reviews/>}/>
                                <Route path="/property/:propertyId" element={<Details/>}/>
                                <Route path="/rentable-properties" element={<RentableProperties/>}/>
                                <Route path="/advanced" element={<AdvancedSearch/>}/>
                                <Route path="/search" element={<SearchResults/>}/>
                                <Route index element={<Home/>}/>
                            </Routes>
                        </Router>
                        <div className="spacer"/>
                        <Footer/>
                    </Suspense>
                </BootstrapProvider>
            </LoadingProvider>
        </>
    );
}

export default App;
