import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <Navbar className="gradient m-0 mt-auto ">
            <Nav>
                <Nav.Link className="text-white m-md-3" href="/privacy">{t('privacy_policy2')}</Nav.Link>
                <Nav.Link className="text-white m-md-3" href="/impressum">{t('impressum')}</Nav.Link>
            </Nav>
        </Navbar>);
};
export default Footer;