import React, {useContext, useEffect} from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Navigation from "../containers/Navigation";
import Card from "react-bootstrap/Card";
import {LoadingContext} from "../components/context/LoadingContext";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";


const Impressum = () => {
    const {t} = useTranslation();
    const {completeLoad} = useContext(LoadingContext);
    useEffect(() => {
        completeLoad();
    })
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t("impressum_page.title", {ns: "seo"})}</title>
                <meta
                    name="keywords"
                    content={t("impressum_page.keywords", {ns: "seo"})}
                />
                <meta
                    name="description"
                    content={t("impressum_page.description", {ns: "seo"})}
                />
            </Helmet>
            <Navigation/>
            <div className="bg-image3 w-100 h-100 d-block position-absolute min-vh-100">&nbsp;</div>
            <Container>
                <Row className="mt-5">
                    <Col>
                        <Card>
                            <Card.Header>
                                <h4 className="text-center">{t('impressum')}</h4>
                            </Card.Header>
                            <Card.Body>
                                <Container>
                                    <h3>{t("company_details", {ns:"impressum"})}:</h3>
                                    <p>{t("company_name", {ns:"impressum"})}: <cite>BZ House Kft.</cite><br/>
                                        {t("company_hq", {ns:"impressum"})}: <cite>2030, Érd, Irén utca 24</cite><br/>
                                        {t("mailing_address", {ns:"impressum"})}: <cite>2030, Érd, Bajcsy-Zsilinszky út 61</cite><br/>
                                        {t("tax_no", {ns:"impressum"})}: <cite>29162246-2-13</cite><br/>
                                        {t("registering_authority", {ns:"impressum"})}: <cite>Budapesti Környéki Törvényszék Cégbíróság</cite><br/>
                                        {t("authority_licence_number", {ns:"impressum"})}: <cite>13-09-211442</cite><br/>
                                        {t("chamber_of_industry", {ns:"impressum"})}: <cite>MKIK - Magyar Kereskedelmi és Iparkamara</cite><br/>
                                        {t("phone_no", {ns:"impressum"})}: <cite>+36 70 600 2393</cite><br/>
                                        {t("email", {ns:"impressum"})}: <cite>bzhousekft@gmail.com</cite></p>
                                    <h3>{t("details_of_service_provider", {ns:"impressum"})}:</h3>
                                    <p>{t("company_name", {ns:"impressum"})}: <cite>Websupport Magyarország Kft.</cite><br/>
                                        {t("address", {ns:"impressum"})}: <cite>H-1119 Budapest, Fehérvári út 97-99</cite><br/>
                                        {t("phone_no", {ns:"impressum"})}: <cite>+36 30 700 4030</cite><br/>
                                        {t("email", {ns:"impressum"})}: <cite>info@ezit.hu</cite></p>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default Impressum;